// @import '~@angular/material/theming';
// @include mat-core();
// $development-primary: mat-palette($mat-indigo);
// $development-accent: mat-palette($mat-pink, A200, A100, A400);
// $development-warn: mat-palette($mat-red);
// $development-theme: mat-light-theme($development-primary, $development-accent, $development-warn);
// @include angular-material-theme($development-theme);

@import 'helpers';
@import 'shapes';
@import 'fonts';
@import 'api-loading';

.project-logo {
  height: 55px;
  margin: 0;
}

.top-project-bar {
  position: relative;
  min-height: 60px;

  a {
    position: relative;
    z-index: 10;
  }

  > span {
    max-width: 350px;
  }

  h1 {
    @include respond(gt-sm) {
      position: absolute;
      width: 100%;
    }
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
}

iframe {
  width: 100%;
  height: 100%;
}

body {
  min-height: 100%;
  font-family: $body-font;
  font-size: 1.3rem;
  white-space: pre-wrap;
  position: relative;
  display: flex;
  flex-direction: column;

  background-image: url('/assets/img/bkg.png');
  background-position: top;
  background-size: contain;
  background-repeat: repeat;

  &.no-scroll {
    overflow: hidden;
  }
}

app-root {
  display: flex;
  flex-direction: column;
  position: relative;

  .site-content {
    position: relative;
    padding-top: 50px;

    @include respond(gt-sm) {
      padding-top: 70px;
    }
  }

  router-outlet~* {
    display: flex;
    flex-direction: column;
  }
}

#{headings(1, 6)} {
  margin: 0;
  font-family: $headers-font;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: normal;
}

h1 {
  font-weight: bold;
}

h2 {
  font-size: 2.6rem;
  font-weight: bold;
}

h3 {
  font-size: 1.8rem;
  font-weight: bold;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
  cursor: pointer;
}

p {
  line-height: 24px;
  text-align: justify;
}

button {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
}

.container {
  width: 100%;
  max-width: 1105px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.quote.bold {
  font-weight: bold;
}

.quote {
  padding: 30px;
  background-color: $bkg-highlight;
  font-family: $headers-font;
  line-height: 32px;
  font-size: 1.8rem;
  text-align: center;
  position: relative;

  &:not(.no-icon)::after {
    // content: '“';
    font-size: 15rem;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-65%);
  }

  @include respond(gt-sm) {
    padding: 30px 70px;
  }
}

.section-title {
  text-align: center;

  h1 {
    font-size: 5rem;
  }

  label {
    display: block;
    font-size: 1.4rem;
  }
}

.history-body {

  >div:first-child {
    order: 2;
    margin-top: 100px;

    @include respond(gt-xs) {
      margin-top: 0;
      order: 0;
    }
  }

  p {
    line-height: 24px;
  }

  .img-wrapper {
    @include bg-cover(cover);

    width: 100%;
    height: 320px;
    @include respond(gt-xs) {
      height: 100%;
    }
    position: relative;

    &::before {
      content: '';
      width: calc(100% + 30px);
      height: 200px;
      background-color: $bkg-highlight;
      position: absolute;
      bottom: -85px;
      left: -60px;
      z-index: -1;
    }
  }
}

.projects-list {
  .history-body {
    &:nth-child(even) {
      >div:first-child {
        order: 2;
      }
    }

    +.history-body {
      margin-top: 70px;
      @include respond(gt-xs) {
        margin-top: 150px;
        margin-bottom: 150px;
      }
    }

    p+a {
      margin-top: 40px;
    }

    p+label {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    label {
      font-size: 2.4rem;
      font-family: $headers-font;
    }

    >div:first-child {
      display: flex;
      flex-direction: column;

      a {
        align-self: flex-start;
      }
    }
  }
}

// *** TO clean up between float and flex
.special-letter-wrapper {
  position: relative;
  display: inline-block;
}

.special-letter {
  font-size: 2em;
  position: absolute;
  top: 50%;

  &.left {
    left: 0%;
    transform: translate(-110%, -60%);
  }

  &.right {
    transform: translate(110%, -75%);
    right: 0%;
  }
}

h1.special-letter-wrapper,
h2.special-letter-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .special-letter {
    position: static;
    transform: translate(0, -8%);
  }
}

h1.special-letter-wrapper {
  .special-letter {
    @include respond(gt-sm) {
      transform: translate(0, -20%);
    }
  }
}

// TO clean up between float and flex ***

.scroll-to-top {
  height: 50px;
  width: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: $black;
  color: $white;
  position: fixed;
  left: 12px;
  bottom: 12px;
  border: 2px solid $white;
  opacity: .7;
  transition: opacity .3s ease-in-out,
    border-radius .3s ease-in-out;
  cursor: pointer;
  padding: 5px;

  svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    opacity: 1;
    border-radius: 50%;
  }

  &.show {
    display: flex;
  }

  .material-icons {
    font-size: 36px;
  }
}
